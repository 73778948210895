<template>
  <div>
    <ion-row class="border-top pt-3">
      <ion-col size="1">
        <div class="item-checkbox" :class="disableCheckbox && 'disabled-checkbox'" @click="checkBox">
          <ion-icon color="primary" v-if="item.selected && !disableCheckbox" :icon="checkbox"></ion-icon>
          <ion-icon color="grey2" v-else-if="disableCheckbox" :icon="squareOutline"></ion-icon>
          <ion-icon color="grey5" v-else :icon="squareOutline"></ion-icon>
        </div>
      </ion-col>
      <ion-col size="2">
        <td-image
          class="product-image"
          :image="item.image"
          :imagePath="IMAGE_PATH.PRODUCT"
          :imageDefault="DEFAULT_PRODUCT_IMAGE"
          :key="item.image"
        ></td-image>
      </ion-col>
      <ion-col size="8" class="pl-3 pr-0">
        <ion-label class="fw-400 name fs-3">
          <ion-text
            v-if="item?.is_sample"
            class="text-primary-green-600 fw-600 h-20px fs-1 text-success-500 success-bg"
          >
            {{ $t('free_sample') }}
          </ion-text>
          {{ item.product_name }}
        </ion-label>
        <ion-row class="mt-1 fs-12 fw-normal justify-space-between">
          <ion-col size="auto" class="px-0 sku-title">
            <ion-label color="grey">
              {{ formatSkuName }}
            </ion-label>
          </ion-col>
          <ion-col align="right" size="auto" class="px-0 sku-desc">
            <ion-label class="" color="grey">
              {{ showQuantity }}
            </ion-label>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12" class="px-0">
            <ion-label class="fs-3 fw-bold" :color="oosColor">{{ oosLabel }}</ion-label>
          </ion-col>
          <ion-col size="12" class="d-flex align-center px-0">
            <div class="btn-delete mr-1" @click="$emit('open-modal-delete', item)">
              <ion-icon :icon="trash"></ion-icon>
            </div>
            <div class="h-100">
              <div v-if="item && item.is_order_by_weight" class="d-flex align-center cnt-weight">
                <ion-input
                  type="number"
                  class="input-weight text-end"
                  :value="formattedWeight"
                  @ionInput="onInputWeight"
                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || (event.target.value.indexOf('.') === -1 && event.charCode === 46)"
                />
                <span class="ml-1">{{ $t('kg') }}</span>
              </div>
              <div v-else class="d-flex align-center">
                <div class="d-flex align-center cnt-weight h-100">
                  <div class="cnt-icon-q" :class="disabledMinusQuantity && 'disabled'" @click="minusQuantity">
                    <ion-icon
                      class="icon-q"
                      :color="disabledMinusQuantity ? 'grey4' : 'light'"
                      :icon="remove"
                    ></ion-icon>
                  </div>
                  <div size="5" class="">
                    <input
                      :disabled="disableInput"
                      v-model.number="orderQuantity"
                      type="number"
                      maxlength="4"
                      class="input-weight"
                      @input="onInputQuantity"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                    />
                  </div>
                  <div class="cnt-icon-q" :class="disabledAddQuantity && 'disabled'" @click="addQuantity">
                    <ion-icon
                      :color="disabledAddQuantity ? 'grey4' : 'light'"
                      class="icon-q"
                      :icon="add"
                    ></ion-icon>
                  </div>
                </div>
              </div>
            </div>
          </ion-col>
        </ion-row>

        <ion-row v-if="soldInUnits && !item.is_out_of_stock">
          <ion-col size="12" class="d-flex align-center px-0 py-0">
            <div class="dummy-spacing mr-1"></div>
            <ion-label v-if="soldInUnits && !item.is_out_of_stock" color="red">
              {{ $t('soldInUnits', { number: item.increment_qty }) }}
            </ion-label>
          </ion-col>
        </ion-row>
        <ion-row v-if="isShowRemaining">
          <ion-col size="12" class="d-flex align-center px-0 py-0">
            <div class="dummy-spacing mr-1"></div>
            <ion-text v-if="!item.is_order_by_weight" color="danger">
              {{ $t('last_remaming_item', { remainingQuantity }) }}
            </ion-text>
            <ion-text v-else color="danger">
              {{ $t('last_remaming_weight_item', { remainingQuantity }) }}
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-col>
      <ion-col size="1" class="pl-3 pr-0">
        <ion-icon :icon="ellipsisVerticalOutline" @click="setOpenMoreAction(true)"></ion-icon>
      </ion-col>
    </ion-row>
    <ion-modal
      mode="ios"
      :is-open="isOpenMoreAction"
      :initial-breakpoint="0.25"
      :breakpoints="[0.25, 0.4]"
      :backdropDismiss="true"
      @didDismiss="setOpenMoreAction(false)"
      class="modal-radius-md"
      :handle="true"
    >
      <cart-item-more-action :cartItem="item" @closeModal="setOpenMoreAction(false)" />
    </ion-modal>
    <!-- <ion-modal
      mode="ios"
      css-class="modal-bottom-sheet-custom-action"
      :is-open="isOpenMoreAction"
      :initial-breakpoint="1"
      :breakpoints="[1]"
      :backdrop-breakpoint="1"
      :handle="false"
    >
      <ion-button
        @click="onApplySampleItem()"
        fill="solid"
        expand="block"
        color="grey"
        class="rounded-sm mx-2 my-2"
      >
        <ion-label class="text-capitalize"> {{ $t('apply') }} </ion-label>
      </ion-button>
    </ion-modal> -->
  </div>
</template>
<script>
import { DEFAULT_PRODUCT_IMAGE, IMAGE_PATH } from '@/modules/sale/constants/index';
import { displayPrice } from '@/modules/sale/services/libs/helper';
import CartItemMoreAction from '@/modules/shared/components/cart/CartItemMoreAction.vue';
import { LIMIT_PRODUCT_REMAINING, MAX_INPUT } from '@/modules/shared/constants/';
import { isLargerMaxInput, isQuantityAMultipleOfIncrement } from '@/modules/shared/utils/';
import { useSampleProduct } from '@/usecases/cart';
import { priceFormatter } from '@/utils/';
import { toastController } from '@ionic/vue';
import { add, checkbox, ellipsisVerticalOutline, remove, square, squareOutline, trash } from 'ionicons/icons';
import debounce from 'lodash.debounce';
import { defineComponent, ref } from 'vue';
export default defineComponent({
  components: { CartItemMoreAction },
  props: {
    item: {
      type: Object,
      required: true
    },
    currencySymbol: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const orderQuantity = ref(1);
    const orderWeight = ref(null);
    const isUpdateWeight = ref(false);
    const openToast = async (message, color = 'primary', position = 'top') => {
      const toast = await toastController.create({
        message,
        position,
        color,
        duration: 2000
      });
      return toast.present();
    };
    const isOpenMoreAction = ref(false);
    const setOpenMoreAction = (state) => (isOpenMoreAction.value = state);
    const { onApplySampleItem } = useSampleProduct(props.item);

    return {
      onApplySampleItem,
      orderQuantity,
      orderWeight,
      isUpdateWeight,
      openToast,
      priceFormatter,
      trash,
      add,
      remove,
      checkbox,
      squareOutline,
      square,
      IMAGE_PATH,
      DEFAULT_PRODUCT_IMAGE,
      setOpenMoreAction,
      ellipsisVerticalOutline,
      isOpenMoreAction
    };
  },

  inject: ['$storage'],

  emits: [
    'toggle-checkbox',
    'update-quantity-weight',
    'open-modal-delete',
    'uncheck-checkbox',
    'remove-zero-price-item'
  ],
  async mounted() {
    this.orderQuantity = this.item.order_qty;
    this.orderWeight =
      this.item?.total_available_quantity > 0 && this.item?.order_weight > this.item?.total_available_quantity
        ? this.item?.total_available_quantity?.toFixed(2)
        : this.item?.order_weight.toFixed(2);
  },
  async beforeUpdate() {
    this.orderWeight = this.isUpdateWeight
      ? this.orderWeight
      : this.item?.total_available_quantity > 0 &&
        this.item?.order_weight > this.item?.total_available_quantity
      ? this.item?.total_available_quantity?.toFixed(2)
      : this.item?.order_weight.toFixed(2);
  },
  watch: {
    $route() {
      this.isUpdateWeight = false;
    },
    'item.order_qty'() {
      this.orderQuantity = this.item.order_qty;
    },
    'item.order_weight'() {
      this.orderWeight =
        this.item.total_available_quantity > 0 && this.item.order_weight > this.item.total_available_quantity
          ? this.item.total_available_quantity
          : this.item.order_weight;
    },
    checkPrice() {
      // Auto remove the cart item if direct price has changes to 0, and auto uncheck checkbox when item price is 0
      if (this.checkPrice <= 0) {
        this.item.price <= 0
          ? this.$emit('remove-zero-price-item', this.item)
          : this.$emit('uncheck-checkbox', this.item);
      }
    }
  },
  computed: {
    oosLabel() {
      return (this.remainingQuantity <= 0 && !this.isBackOrder) || this.item.is_out_of_stock
        ? this.$t('out_of_stock')
        : this.item.is_unavailable
        ? this.$t('no_longer_available')
        : this.price;
    },
    oosColor() {
      return (this.remainingQuantity <= 0 && !this.isBackOrder) ||
        this.item.is_out_of_stock ||
        this.item.is_unavailable
        ? 'danger'
        : 'primary';
    },
    showQuantity() {
      if (this.item.is_order_by_weight) return;
      return `${this.orderQuantity} ${this.item.oom}(s)`;
    },
    price() {
      const price = !this.item.is_order_by_weight
        ? displayPrice(this.item.price * this.orderQuantity)
        : displayPrice((this.item.price / this.item.weight) * Number(this.orderWeight));
      const priceValue = this.item.is_sample ? 0 : price;
      return priceFormatter(this.currencySymbol, priceValue);
    },
    checkPrice() {
      return !this.item.is_order_by_weight
        ? this.item.price * this.orderQuantity
        : (this.item.price / this.item.weight) * Number(this.orderWeight);
    },
    soldInUnits() {
      return !isQuantityAMultipleOfIncrement({
        quantity: this.orderQuantity,
        increment: this.item.increment_qty
      });
    },
    disableCheckbox() {
      return this.isItemDisable(this.item);
    },
    disabledAddQuantity() {
      if (this.isBackOrder || this.remainingQuantity === null) {
        return false;
      } else {
        return (
          this.orderQuantity >= MAX_INPUT ||
          this.orderQuantity >= this.remainingQuantity ||
          this.remainingQuantity <= 0 ||
          this.item.is_out_of_stock ||
          this.item.is_unavailable
        );
      }
    },
    disabledMinusQuantity() {
      if (this.isBackOrder || this.remainingQuantity === null) {
        return this.orderQuantity <= 1 ? true : false;
      } else {
        return (
          this.orderQuantity <= this.item.increment_qty ||
          this.remainingQuantity <= 0 ||
          this.item.is_out_of_stock ||
          this.item.is_unavailable
        );
      }
    },
    disableInput() {
      if (this.isBackOrder || this.remainingQuantity === null) {
        return false;
      } else {
        return (
          this.remainingQuantity <= 0 ||
          this.item.is_out_of_stock ||
          this.orderQuantity > this.remainingQuantity ||
          this.item.is_unavailable
        );
      }
    },
    isBackOrder() {
      return this.item?.is_back_order ?? null;
    },
    remainingQuantity() {
      if (this.item.total_available_quantity < 0 || this.item.total_available_quantity === null) {
        return MAX_INPUT;
      } else {
        return this.item?.total_available_quantity ?? MAX_INPUT;
      }
    },
    isShowRemaining() {
      return !this.isBackOrder &&
        !this.item.is_out_of_stock &&
        this.remainingQuantity <= LIMIT_PRODUCT_REMAINING &&
        this.remainingQuantity > 0
        ? true
        : false;
    },
    formattedWeight() {
      return this.orderWeight;
    },
    formatSkuName() {
      //remove product name and use this regex "/^[\s,]+/" to clear space and comma before sku name
      return this.item.sku.replace(this.item.product_name, '').replace(/^[\s,]+/, '');
    }
  },

  methods: {
    onInputWeight: function (event) {
      this.isUpdateWeight = true;
      const maxWeight = 999999.99;
      let value = event.target.value;
      const decimalIndex = value.indexOf('.');
      if (decimalIndex !== -1 && value.slice(decimalIndex + 1).length > 2) {
        // truncate to two decimal places
        value = Math.trunc(parseFloat(value) * 100) / 100;
        event.target.value = value;
      }
      this.orderWeight = value;
      const selected = this.orderWeight <= 0 ? false : this.item.selected;
      if (!this.isBackOrder && this.orderWeight > this.remainingQuantity) {
        this.orderWeight = this.remainingQuantity.toFixed(2);
        event.target.value = this.orderWeight;
        this.openToast(this.$t('out_of_max_weight', { weight: this.remainingQuantity.toFixed(2) }), 'danger');
      } else {
        if (this.formattedWeight > maxWeight) {
          this.openToast(this.$t('out_of_max_weight', { weight: maxWeight }), 'danger');
          this.orderWeight = maxWeight.toFixed(2);
          event.target.value = this.orderWeight;
        }
      }
      const item = {
        ...this.item,
        order_weight: this.orderWeight,
        selected
      };
      this.$emit('update-quantity-weight', item);
    },
    checkBox() {
      this.$emit('toggle-checkbox', this.item);
    },
    isItemDisable(item) {
      if (item.is_order_by_weight) {
        return (
          (!item.is_back_order &&
            item.total_available_quantity !== null &&
            (item.order_weight && this.orderWeight) > item.total_available_quantity) ||
          item.is_out_of_stock ||
          !item.order_weight ||
          item.is_unavailable
        );
      } else {
        return (
          !isQuantityAMultipleOfIncrement({
            quantity: item.order_qty,
            increment: item.increment_qty
          }) ||
          (!item.is_back_order &&
            item.total_available_quantity !== null &&
            item.order_qty > item.total_available_quantity) ||
          item.is_out_of_stock ||
          !item.order_qty ||
          item.is_unavailable
        );
      }
    },
    updateItemDebounce: debounce(function (item) {
      this.$emit('update-quantity-weight', item);
    }, 50),

    onInputQuantity() {
      // process check input value
      this.orderQuantity = Number(`${this.orderQuantity}`.replace(/[^\d]/g, ''));

      if (isLargerMaxInput(this.orderQuantity)) {
        this.orderQuantity = MAX_INPUT;
      } else {
        if (!this.isBackOrder && this.orderQuantity > this.remainingQuantity) {
          const increment = Number(this.item.increment_qty);
          this.openToast(this.$t('out_of_max_quantity', { quantity: this.remainingQuantity }), 'danger');
          this.orderQuantity = this.remainingQuantity - (this.remainingQuantity % increment);
        }
      }

      if (
        !isQuantityAMultipleOfIncrement({
          quantity: this.orderQuantity,
          increment: this.item.increment_qty
        })
      ) {
        this.updateQuantity(
          {
            qty: this.orderQuantity,
            selected: false
          },
          true
        );
      } else {
        this.updateQuantity(
          {
            qty: this.orderQuantity
          },
          true
        );
      }
    },

    addQuantity() {
      if (
        (this.remainingQuantity <= 0 && !this.isBackOrder) ||
        this.item.is_unavailable ||
        this.disabledAddQuantity
      ) {
        return;
      }
      const quantity = Number(this.orderQuantity) || 0;
      const increment = Number(this.item.increment_qty);
      if (isLargerMaxInput(quantity + increment)) {
        this.orderQuantity = MAX_INPUT;
      } else {
        if (!this.isBackOrder) {
          this.orderQuantity = quantity + increment - (quantity % increment);
          if (this.orderQuantity > this.remainingQuantity) {
            this.openToast(this.$t('out_of_max_quantity', { quantity: this.remainingQuantity }), 'danger');
            this.orderQuantity = this.remainingQuantity - (this.remainingQuantity % increment);
          }
        } else {
          this.orderQuantity = quantity + increment - (quantity % increment);
        }
      }
      this.updateQuantity(
        {
          qty: this.orderQuantity
        },
        true
      );
    },
    minusQuantity() {
      if (
        (this.remainingQuantity <= 0 && !this.isBackOrder) ||
        this.item.is_unavailable ||
        this.disabledMinusQuantity
      ) {
        return;
      }
      const quantity = Number(this.orderQuantity) || 0;
      const increment = Number(this.item.increment_qty);
      if (quantity <= increment) {
        this.orderQuantity = increment;
      } else if (
        isQuantityAMultipleOfIncrement({
          quantity: quantity,
          increment: increment
        })
      ) {
        this.orderQuantity -= increment;
      } else {
        this.orderQuantity -= quantity % increment;
      }
      this.updateQuantity({
        qty: this.orderQuantity
      });
    },

    updateQuantity(data, isDebounce = false) {
      const item = {
        ...this.item,
        order_qty: data.qty
      };
      if (data.selected !== undefined) item.selected = data.selected;

      if (data.qty <= 0) item.selected = false;

      if (isDebounce) {
        this.updateItemDebounce(item);
      } else {
        this.$emit('update-quantity-weight', item);
      }
    }
  }
});
</script>
<style lang="scss" scoped>
.btn-delete {
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  background-color: #eceff1;
  border-radius: 3px;
}

.btn-delete ion-icon {
  font-size: 15px;
  color: #9e9e9e;
}

.product-image {
  width: 57px;
}

ion-label {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.name {
    white-space: pre-wrap;
    line-height: 20px;
  }
}

.cnt-weight {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.input-weight {
  border: 0;
  width: 65px;
  height: 30px;
  max-height: 30px;
  text-align: center;
  background-color: white;

  &:focus {
    outline: none;
  }
}

.cnt-icon-q {
  background-color: var(--ion-color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 3px;
}

.disabled-checkbox {
  pointer-events: none;
}

.disabled {
  background-color: #eeeeee;
  color: var(--ion-color-grey3);
}

.icon-q {
  font-size: 15px;
  color: white;
}

.sku-title {
  min-width: 0;
  flex-grow: 1 !important;
  flex-basis: 0% !important;
}

.sku-desc {
  margin-left: 0.5rem;
  text-align: right;
}

// hide input number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.h-20px {
  height: 20px;
}
</style>
